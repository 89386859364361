exports.components = {
  "component---src-components-article-tag-js": () => import("./../../../src/components/ArticleTag.js" /* webpackChunkName: "component---src-components-article-tag-js" */),
  "component---src-components-blog-article-js": () => import("./../../../src/components/BlogArticle.js" /* webpackChunkName: "component---src-components-blog-article-js" */),
  "component---src-components-doc-article-js": () => import("./../../../src/components/DocArticle.js" /* webpackChunkName: "component---src-components-doc-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-gfx-converter-js": () => import("./../../../src/pages/gfx/converter.js" /* webpackChunkName: "component---src-pages-gfx-converter-js" */),
  "component---src-pages-gfx-icon-pack-js": () => import("./../../../src/pages/gfx/iconPack.js" /* webpackChunkName: "component---src-pages-gfx-icon-pack-js" */),
  "component---src-pages-gfx-index-js": () => import("./../../../src/pages/gfx/index.js" /* webpackChunkName: "component---src-pages-gfx-index-js" */),
  "component---src-pages-gfx-request-js": () => import("./../../../src/pages/gfx/request.js" /* webpackChunkName: "component---src-pages-gfx-request-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

